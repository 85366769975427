import ApplicationAdapter from './application';

interface UserQuery {
  me?: boolean;
}

export default class User extends ApplicationAdapter {
  namespace = 'api/v1';

  urlForQueryRecord(query: UserQuery) {
    let originalUrl = super.urlForQueryRecord(query, 'user');
    if (query.me) {
      delete query.me;
      return `${originalUrl}/me`;
    }

    return originalUrl;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    user: User;
  }
}
