import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthRoute extends Route {
  @service router;
  @service session;
  @service intl;
  @service features;

  async beforeModel(transition) {
    if (!this.session.isTokenValid) {
      this.session.redirectUri = transition.intent.url;
      return this.router.transitionTo('login');
    }
    await this.session.loadOwnUser();
    if (this.features.isEnabled('i18n')) {
      this.updateLocale();
    }
    return true;
  }

  updateLocale() {
    let locale = this.session.user.preferences?.locale;
    locale = locale ?? this._getUserBrowserLocale();
    this.intl.setLocale([locale]);
  }

  _getUserBrowserLocale() {
    const browserLang = navigator.language.toLowerCase().split('-')[0];
    // only take care of the first part of the language code
    const supportedLocales = this.intl.locales;
    const locale = supportedLocales.find((supportedLocale) => {
      return supportedLocale.startsWith(browserLang);
    });
    return locale ?? supportedLocales[0];
  }
}
