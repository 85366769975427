import Model, { attr } from '@ember-data/model';
import normalizeLocaleToApi from 'client-app-omnivise-web/utils/get-normalized-locale';

export default class extends Model {
  @attr('string') email;

  @attr({
    defaultValue: () => {
      return {};
    },
  })
  preferences;

  updateLocale(locale) {
    this.preferences
      ? (this.preferences.locale = locale)
      : (this.preferences = { locale: locale });
  }

  get serializedPreferences() {
    const locale = this.preferences?.locale ?? 'en-us';
    return {
      locale: normalizeLocaleToApi(locale)[0],
    };
  }
}
