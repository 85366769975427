import Service from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { tracked } from '@glimmer/tracking';
import localStorage from 'ember-local-storage-decorator';
import * as Sentry from '@sentry/ember';
import logErrorUtil from 'client-app-omnivise-web/utils/log-error';
import IdentityProviderFactory from 'client-app-omnivise-web/utils/identity-providers/idp-factory';
import config from 'client-app-omnivise-web/config/environment';
import { runTask } from 'ember-lifeline';

class UnauthenticatedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthenticatedError';
  }
}

// Internal to check for logout events in milliseconds
const INTERVAL_TO_CHECK_FOR_LOGOUT_EVENTS = 500;

// Time an access token will be refreshed before it expires in seconds
const REFRESH_THRESHOLD = 300;

export const LOGOUT_COOKIE_DOMAIN = window.location.hostname.replace(
  'home.',
  '',
);
const LOGOUT_COOKIE_NAME = 'logout-time';

export default class SessionService extends Service {
  @service router;
  @service store;
  @localStorage() accessTokens = {};
  @localStorage() provider = null;
  @localStorage() accessTokenRefreshLock = null;
  idp = IdentityProviderFactory.createFacade(this.requiredIdps);

  timeOfLogoutCheck;
  @tracked tokenExpired = false;

  /**
   * @public
   */
  get isAuthenticated() {
    return this.isTokenValid && this.isUserValid;
  }

  get isTokenValid() {
    const accessToken = this.accessTokens[this.ownTrustZone];
    if (!accessToken) return false;
    const { exp } = jwtDecode(accessToken);
    const isTokenValid = exp > Date.now() / 1000;
    return isTokenValid;
  }

  get isUserValid() {
    return config.environment === 'test' || !!this.user;
  }

  get config() {
    return getOwner(this).resolveRegistration('config:environment');
  }

  get ownTrustZone() {
    return this.config.authConfig.ownAuthTrustZone;
  }

  get environment() {
    return this.config.environment;
  }

  get requiredIdps() {
    const authConfig = this.config.authConfig;
    const mainIdps = authConfig.mainIdps ? authConfig.mainIdps.split(',') : [];

    const secondaryIdps = authConfig.secondaryIdps
      ? authConfig.secondaryIdps.split(',')
      : [];
    return [...mainIdps, ...secondaryIdps];
  }

  get currentTime() {
    return new Date().getTime();
  }

  /**
   * @method setLogoutCookie
   * @return void
   * @private
   */
  setLogoutCookie() {
    Cookies.set(LOGOUT_COOKIE_NAME, this.currentTime, {
      domain: LOGOUT_COOKIE_DOMAIN,
      expires: 1, // expiration time in days
    });
  }

  get redirectUri() {
    return window.sessionStorage.getItem('redirectUri');
  }

  set redirectUri(redirectUri) {
    if (redirectUri) {
      window.sessionStorage.setItem('redirectUri', redirectUri);
    } else {
      this.clearRedirectUri();
    }
  }

  clearRedirectUri() {
    window.sessionStorage.removeItem('redirectUri');
  }

  async redirectToDestinationAfterAuthentication() {
    const targetUrl = this.redirectUri ?? '/auth';
    const isInternalUrl = targetUrl.startsWith('/');

    this.clearRedirectUri();

    if (isInternalUrl) {
      const transition = this.router.transitionTo(targetUrl);
      await transition.followRedirects();
    } else {
      window.location.href = targetUrl;
      await new Promise((resolve, reject) => setTimeout(reject, 0));
    }
  }

  get ownAccessToken() {
    const accessToken = this.accessTokens[this.ownTrustZone];

    // TODO: Verify not only that something exists in local storage but also
    //       that it's a valid access token and that it's not expired.
    if (!accessToken) {
      throw new UnauthenticatedError(
        'Tried to use access token for own scope, which does not exist',
      );
    }

    return accessToken;
  }

  async pickupSessionState() {
    //To handle the edge case with azureAD Logging OUT
    if (this.provider === 'loggingOut') {
      // eslint-disable-next-line
      console.log('loggingOut');
      this.cleanUpAfterLogout();
      return;
    }

    if (!this.isTokenValid) {
      this.cleanUpAfterLogout();
    }

    const accessTokenInfo = await this.idp.pickupSessionState(this.provider);
    if (accessTokenInfo) {
      this.setupUserInSentry();
      this.updateLocalStorageAccessToken(
        this.ownTrustZone,
        accessTokenInfo.accessToken,
        accessTokenInfo.provider,
      );
      await this.loadOwnUser();

      this.tokenExpired = false;
      this.scheduleTokenRefreshProcess();
      this.listenForLogoutEvents();
    }
  }

  // This method ensures that a valid access token exists for the trust zone
  // given as first argument.
  //
  // It does so by following three steps:
  // 1. It first checks if a valid, not yet expired access token exists in
  //    local storage for this trust zone.
  // 2. If not, it tries to fetch an access token from Okta. If that failes
  //    (e.g. because no Okta session exists), it let's the consumer handle
  //    the error.
  // 3. If it has fetched an access token successfully, that token is persisted
  //    in local storage.
  async ensureAccessTokenFor(trustZone) {
    // Check if we have an access token for that scope already
    const existingAccessToken = this.accessTokens[trustZone];
    if (existingAccessToken) {
      // try to parse the existing access token as JWT
      try {
        const { exp } = jwtDecode(existingAccessToken);
        if (exp - new Date().getTime() / 1000 > REFRESH_THRESHOLD) {
          // existing access token is valid and not expired yet
          return;
        }
      } catch (error) {
        // existing access token can not be parsed as JWT
        // we need to fetch a new one
        logErrorUtil.logError(error);
      }
    }
    const accessToken = await this.idp.ensureAccessTokenFor(
      trustZone,
      this.provider,
    );

    this.setupUserInSentry();
    this.updateLocalStorageAccessToken(
      trustZone,
      accessToken || this.accessTokens[this.ownTrustZone],
      this.provider,
    );
  }

  async ensureAccessTokenForDefaultTrustZone() {
    return this.ensureAccessTokenFor(this.ownTrustZone);
  }

  listenForLogoutEvents() {
    if (this.logoutListenerIntervalId) {
      // if we are already listening, dont create another interval listener
      return;
    }

    this.logoutListenerIntervalId = setInterval(async () => {
      if (!this.isAuthenticated) {
        return;
      }
      const logoutTime = parseInt(
        Cookies.get(LOGOUT_COOKIE_NAME, {
          domain: LOGOUT_COOKIE_DOMAIN,
        }),
      );
      const logoutDetected = logoutTime && logoutTime >= this.timeOfLogoutCheck;

      // No need to guard against `NaN` (not a number). Comparing two values
      // always returns `false` if one of them is `NaN`.
      if (logoutDetected) {
        this.stopListeningForLogoutEvents();
        this.accessTokens = {};
      } else {
        this.timeOfLogoutCheck = this.currentTime;
      }
    }, INTERVAL_TO_CHECK_FOR_LOGOUT_EVENTS);

    registerDestructor(this, () => clearTimeout(this.logoutListenerIntervalId));
  }

  stopListeningForLogoutEvents() {
    clearTimeout(this.logoutListenerIntervalId);
  }

  stopRefreshTokenProcess() {
    clearTimeout(this.tokenRefreshIntervalId);
    this.tokenRefreshIntervalId = null;
  }

  updateLocalStorageAccessToken(scope, token, provider) {
    this.accessTokens = {
      ...this.accessTokens,
      [scope]: token,
    };
    this.provider = provider;
  }

  scheduleTokenRefreshProcess() {
    if (this.tokenRefreshIntervalId) return;
    const lockExists = this.accessTokenRefreshLock !== null;
    const thirtySecondsAgo = Date.now() - 30 * 1000;
    const lockIsRecent = this.accessTokenRefreshLock > thirtySecondsAgo;

    this.tokenRefreshIntervalId = setInterval(async () => {
      // check lock
      if (lockExists && lockIsRecent) {
        // some other instance must be handling it
        return;
      }
      // Either the lock is not present or it is older than 30 secs.
      // so let's check each token to see if we need to refresh

      //set the lock ourselves
      this.accessTokenRefreshLock = Date.now();

      try {
        for (const [authScope, accessToken] of Object.entries(
          this.accessTokens,
        )) {
          const { exp } = jwtDecode(accessToken);
          if (exp - Date.now() / 1000 < REFRESH_THRESHOLD) {
            const updatedAccessToken = await this.idp.update(
              this.provider,
              authScope,
            );
            if (updatedAccessToken) {
              this.updateLocalStorageAccessToken(
                authScope,
                updatedAccessToken,
                this.provider,
              );
              // update lock
              this.accessTokenRefreshLock = Date.now();
            }
          }
        }
      } finally {
        // remove lock
        this.accessTokenRefreshLock = null;
        if (this.tokenExpired) this.stopRefreshTokenProcess();
      }
    }, 30 * 1000);

    registerDestructor(this, () => clearTimeout(this.tokenRefreshIntervalId));
  }

  @action
  async invalidate() {
    try {
      await this.store.unloadAll();

      this.clearUserInSentry();

      this.provider = 'loggingOut';
      await this.idp.logout();
    } finally {
      this.cleanUpAfterLogout();
    }
  }

  cleanUpAfterLogout() {
    // Unloading all records from store to avoid any data leakage between users.
    // Why are we using runTask? Because I think we want to run this task in the next runloop.
    // in case this is still relevant: https://github.com/emberjs/data/issues/5447#issuecomment-845672812
    runTask(
      this,
      () => {
        this.store.unloadAll();
      },
      0,
    );

    // delete all access tokens from local storage
    // It informs other running instances that a logout event has happend.
    // It must be done _after_ the Okta session is destroyed otherwise we
    // may see crazy timing issues.
    //modifying  accesstokens triggers modifier (redirect-to-login-if-unauthenticated) and navigates to login with queryParams
    // We dont need to listen to logout events.

    this.stopListeningForLogoutEvents();
    //We dont need to refresh token anymore.
    this.stopRefreshTokenProcess();
    // Set Logout Cookie for other application to get logout.
    this.setLogoutCookie();
    this.accessTokens = {};
  }

  async login(provider) {
    this.provider = provider;
    const accessToken = await this.idp.login(provider);

    if (accessToken) {
      this.setupUserInSentry();

      this.updateLocalStorageAccessToken(
        this.ownTrustZone,
        accessToken,
        this.provider,
      );
      await this.loadOwnUser();
    }
  }

  async loadOwnUser() {
    if (this.user) return;
    try {
      const user = await this.store.queryRecord('user', { me: true });
      this.user = user;
    } catch (e) {
      logErrorUtil.logError(e);
    }
  }

  setupUserInSentry() {
    Sentry.setUser({ email: this.currentUserEmail });
  }

  clearUserInSentry() {
    Sentry.setUser(null);
  }
  /**
   * The claims that are encoded in our own access token.
   *
   * @property ownClaims
   * @type {Object}
   * @private
   */
  get ownClaims() {
    return jwtDecode(this.ownAccessToken);
  }

  get currentUserId() {
    return this.ownClaims.uid;
  }

  get currentUserFirstName() {
    if (this.isAuthenticated) {
      return (
        this.ownClaims['given_name'] ||
        this.ownClaims['first_name'] ||
        this.ownClaims['name']
      );
    }
    return false;
  }

  get currentUserLastName() {
    if (this.isAuthenticated) {
      return this.ownClaims['family_name'] || this.ownClaims['last_name'] || '';
    }
    return false;
  }

  get currentUserEmail() {
    if (this.isAuthenticated) {
      return this.ownClaims['email'];
    }
    return false;
  }
}
